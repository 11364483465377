/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
/* LEVEL 1 */
div.sub1 {
  float: left;
  margin-top: 10px;
  width: 100%;
  text-align: center;
}
.sub1 > .item {
  float: none;
  display: inline-block;
  border-right: 1px solid #8b8b8b;
}
.sub1 > .item.init {
  border-left: 1px solid #8b8b8b;
}
.sub1 > .item:hover > .menu,
.sub1 > .item:focus > .menu {
  background: #fff;
  color: #000;
}
div.sub1 .menu {
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', helvetica, sans-serif;
  font-weight: bold;
  line-height: 1.15;
  padding: 18px 18px 8px;
  -o-transition: background 0.2s ease-in;
  -moz-transition: background 0.2s ease-in;
  -webkit-transition: background 0.2s ease-in;
  transition: background 0.2s ease-in;
}
div.sub1 a.path {
  background: #fff;
  color: #000;
}
/* LEVEL 2 */
div.sub2 {
  text-align: left;
  position: absolute;
  left: 50%;
  top: 100%;
  background: #fff;
  width: 700px;
  margin-left: -390px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
  padding: 0 40px;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  -o-transition: opacity 0.18s, max-height 0.001s 0.18s;
  -moz-transition: opacity 0.18s, max-height 0.001s 0.18s;
  -webkit-transition: opacity 0.18s, max-height 0.001s 0.18s;
  transition: opacity 0.18s, max-height 0.001s 0.18s;
}
div.sub1 > .item:hover div.sub2 {
  max-height: 10000px;
  opacity: 1;
  -o-transition: opacity 0.18s;
  -moz-transition: opacity 0.18s;
  -webkit-transition: opacity 0.18s;
  transition: opacity 0.18s;
}
.sub2 > .item {
  width: 100%;
  margin: 15px 0;
}
.sub2 > .item.init {
  margin-top: 35px;
}
.sub2 > .item.exit {
  margin-bottom: 35px;
}
div.sub2 .menu {
  display: inline-block;
  font-size: 22px;
  line-height: 1.1;
  color: #f18e00;
  padding: 0;
}
.cbe--edit-navigation div.sub1 > .item.path div.sub2 {
  max-height: 10000px !important;
  opacity: 1 !important;
}
/******* layout-large.less 2013-1-16 *******/
.desk {
  max-width: 94%;
}
#home {
  margin-left: 2.90598291%;
}
.cb-layout2 #head,
.cb-layout3 #head {
  width: 80.34188034%;
  margin: 50px 9.82905983% 0;
}
.vcard {
  margin-left: 2.90598291%;
}
.footerhome {
  margin-right: 2.90598291%;
}
#pfad {
  padding-right: 2.90598291%;
  padding-left: 2.90598291%;
}
.contactlink:hover {
  right: -20px;
}
#naviover {
  display: none;
}
.cb-layout1 h1 {
  padding-right: 0;
  padding-left: 0;
}
#contact {
  top: 150px;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.49647887%;
  margin-left: 1.49647887%;
}
.area .part,
.area > .grid table {
  margin-right: 1.49647887%;
  margin-left: 1.49647887%;
  width: 97.00704225%;
}
.area .tiny {
  width: 22.00704225%;
}
.area > .slim {
  width: 25%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 5.98591549%;
  margin-left: 5.98591549%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 88.02816901%;
}
.area > .slim .tiny {
  width: 88.02816901%;
}
.cb-layout2 .main {
  width: 100%;
}
.cb-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main h2,
.cb-layout2 .main .foot {
  margin-right: 2.04081633%;
  margin-left: 2.04081633%;
}
.cb-layout2 .main .part,
.cb-layout2 .main > .grid table {
  margin-right: 2.04081633%;
  margin-left: 2.04081633%;
  width: 95.91836735%;
}
.cb-layout2 .main .tiny {
  width: 45.91836735%;
}
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  width: 95.91836735%;
}
.cb-layout3 .main {
  width: 100%;
}
.cb-layout3 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .main h2,
.cb-layout3 .main .foot {
  margin-right: 2.04081633%;
  margin-left: 2.04081633%;
}
.cb-layout3 .main .part,
.cb-layout3 .main > .grid table {
  margin-right: 2.04081633%;
  margin-left: 2.04081633%;
  width: 95.91836735%;
}
.cb-layout3 .main .tiny {
  width: 45.91836735%;
}
.cb-layout3 .main > .slim {
  width: 50%;
}
.cb-layout3 .main > .slim h2,
.cb-layout3 .main > .slim .foot,
.cb-layout3 .main > .slim .part,
.cb-layout3 .main > .slim.grid table {
  margin-right: 4.08163265%;
  margin-left: 4.08163265%;
}
.cb-layout3 .main > .slim .part,
.cb-layout3 .main > .slim.grid table {
  width: 91.83673469%;
}
.cb-layout3 .main > .slim .tiny {
  width: 91.83673469%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base h2,
.base .foot {
  margin-right: 2.90598291%;
  margin-left: 2.90598291%;
}
.base .part,
.base > .grid table {
  margin-right: 2.90598291%;
  margin-left: 2.90598291%;
  width: 94.18803419%;
}
.base .tiny {
  width: 27.52136752%;
}
.base > .slim {
  width: 33.33333333%;
}
.base > .slim h2,
.base > .slim .foot,
.base > .slim .part,
.base > .slim.grid table {
  margin-right: 8.71794872%;
  margin-left: 8.71794872%;
}
.base > .slim .part,
.base > .slim.grid table {
  width: 82.56410256%;
}
.base > .slim .tiny {
  width: 82.56410256%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.south h2,
.south .foot {
  margin-right: 1.49647887%;
  margin-left: 1.49647887%;
}
.south .part,
.south > .grid table {
  margin-right: 1.49647887%;
  margin-left: 1.49647887%;
  width: 97.00704225%;
}
.south .tiny {
  width: 47.00704225%;
}
.south > .slim {
  width: 50%;
}
.south > .slim h2,
.south > .slim .foot,
.south > .slim .part,
.south > .slim.grid table {
  margin-right: 2.99295775%;
  margin-left: 2.99295775%;
}
.south > .slim .part,
.south > .slim.grid table {
  width: 94.01408451%;
}
.south > .slim .tiny {
  width: 44.01408451%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
div.area {
  margin-top: 40px;
  margin-bottom: 40px;
}
.main {
  width: 97.09401709%;
  margin-left: 1.45299145%;
}
.cb-layout2 .main,
.cb-layout3 .main {
  width: 83.76068376%;
  margin-left: 8.11965812%;
}
.base {
  width: 100%;
  margin-left: 0;
}
.south {
  width: 97.09401709%;
  margin-left: 1.45299145%;
}
.cb-layout2 div.main div.unit.slim div.tall {
  float: right;
  width: 75.51020408%;
}
.cb-layout2 div.main div.unit.slim div.tiny {
  float: left;
  width: 16.32653061%;
}
.cb-layout3 div.main div.unit.slim div.tall {
  float: right;
  width: 59.18367347%;
}
.cb-layout3 div.main div.unit.slim div.tiny {
  float: left;
  width: 24.48979592%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-large.css.map */